<template>
  <v-app>
    <v-container fluid>
      <h3>Connect your domain</h3>
      <v-btn
          text
          :to="{ name: 'manage-websites', params: { domainname: siteUrl } }"
          outlined
          class="btn btn-lg btn-secondary-main"
          style="background: transparent"
      >
        <i class="fas fa-arrow-left"></i> Back
      </v-btn>
      <span
          class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right"
      >
        <i class="fa fa-globe"></i>
        {{ siteUrl }}
      </span>
      <br/>
      <br/>
      <v-row>
        <v-card-title></v-card-title>
        <v-card-text>
          <b-form
              v-if="
              (website && website.subscription.type == 'paid') ||
              website.lifetime_membership
            "
              class="kt-form"
              @submit.stop.prevent="oldDomain"
          >
            <b-input-group prepend="www." class="mb-2 mr-sm-2 mb-sm-0">
              <b-input
                  id="inline-form-input-old-domain"
                  placeholder="your-domain-name.com"
                  name="example-input-1"
                  v-model="$v.form.oldDomain.$model"
                  :state="validateState('oldDomain')"
              ></b-input>
              <b-form-invalid-feedback id="input-0-live-feedback"
              >{{ oldDomainMsg }}
              </b-form-invalid-feedback>
            </b-input-group>
            <br/>

            <v-btn
                v-if="
                oldDomainMsg == 'Already added need verification' ||
                oldDomainMsg == 'Already verified'
              "
                type="submit"
                color="white--text v-btn theme--light elevation-2 v-size--large primary"
                class="btn-save-popup"
                text
                :loading="isBusy"
                @click.prevent="verifyNow($v.form.oldDomain.$model)"
            >Verify Now
            </v-btn>

            <v-btn
                v-else-if="oldDomainMsg == null"
                type="submit"
                color="white--text v-btn theme--light elevation-2 v-size--large primary"
                class="btn-save-popup"
                text
                :loading="isBusy"
            >Continue
            </v-btn>
          </b-form>
          <v-row v-else>
            <v-col cols="12" align="center">
              <progressive-img
                  width="50"
                  position="center"
                  class="w-50"
                  contain
                  src="/assets/media/illustrations/undraw_Notify_re_65on.svg"
              ></progressive-img>
            </v-col>

            <v-col cols="12" align="center">
              <h2>Connect your domain</h2>
              <div v-if="!exist_in_cart">
                <p>
                  If you have an existing website, you can connect it our website
                  plan.
                </p>
                <p>
                  This feature is only available to paid subscription plan.<br/>
                  <a href="#" class="btn btn-primary text-white mt-5" @click="manageSubscription">Upgrade Plan</a>
                </p>
              </div>
              <div v-else>
                <p>
                  The plan up-gradation for the domain is under processing .
                  Please visit your cart and process it.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import Website from "@/services/Websites/WebsiteService";
import {mapState} from "vuex";
import ApiService from "@/common/api.service";
import {validationMixin} from "vuelidate";
import {email, required, minLength, sameAs} from "vuelidate/lib/validators";
import DomainVerificationService from "@/services/domain/DomainVerificationService";
import CartService from "@/services/cart/CartService";

const WebsiteService = new Website();
const verificationService = new DomainVerificationService();
const cartService = new CartService();

export default {
  name: "custom-domain",
  // props: ["domain_name"],
  mixins: [validationMixin],
  data() {
    return {
      isBusy: false,
      exist_in_cart: false,
      oldDomainMsg: null,
      form: {
        oldDomain: "",
      },
      website: {},
    };
  },
  validations: {
    form: {
      oldDomain: {
        required,
        async isUnique(value) {
          if (value === "") return true;
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              verificationService
                  .verifyDomain(this.$v.form.oldDomain.$model)
                  .then((response) => {
                    this.oldDomainMsg = response.data.msg;
                    resolve(response.data.status);
                  });
            }, 100);
          });
        },
      },
    },
  },
  mounted() {
    if (this.domain_name != null && this.domain_name != undefined)
      this.getWebsite();
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    siteUrl() {
      return this.$route.params.domainname;
    },
    domain_name() {
      return this.$route.params.domainname;
    },
  },
  methods: {
    getWebsite() {
      WebsiteService.get(this.domain_name).then((res) => {
        //     console.log(res.data.website)
        this.website = res.data.website;
        this.checkExistInCart();
      });
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        newDomain: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    oldDomain() {
      this.$v.form.oldDomain.$touch();
      if (this.$v.form.oldDomain.$anyError) {
        return;
      }
      const oldDomain = this.$v.form.oldDomain.$model;
      let website = {
        domain_name: oldDomain,
        is_custom_domain: 1,
      };
      // set spinner to submit button

      setTimeout(() => {
        if (this.domain_name != null && this.domain_name != undefined) {
          this.isBusy = true;
          this.update(website);
        } else {
          this.isBusy = true;
          this.save(website);
        }
      }, 2000);
    },
    save(website) {
      verificationService
          .store(website)
          .then((res) => {
            this.isBusy = false;
            website = {};
            this.$router.push({
              name: "domain-verfication",
              params: {domain_name: res.data.domain.domain_name},
            });
          })
          .catch((err) => {
          })
          .finally(() => (this.isBusy = false));
    },
    update(website) {
      website.is_custom_domain = 1;
      WebsiteService.update(this.domain_name, website)
          .then((res) => {
            this.isBusy = false;
            this.save(website);
          })
          .catch((err) => {
          })
          .finally(() => (this.isBusy = false));
    },
    verifyNow(domain) {
      this.$router.push({
        name: "domain-verfication",
        params: {domain_name: domain},
      });
    },

    manageSubscription() {
      window.location.href =
          process.env.VUE_APP_SERVER_URL + this.siteUrl + "/pricing";
    },

    checkExistInCart() {
      cartService.getDomainExistWithPlanInCart(this.siteUrl).then(response => {
        this.exist_in_cart = response.data
      })
    }
  },
};
</script>
<style scoped>
.card-header a {
  font-size: 1.25em;
  text-align: left;
}
</style>
